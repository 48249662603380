import Button from "../../Components/Button/Button";
import classes from "./HomePageAffordable.module.css";
import work1 from "../../Assets/Images/work1.png";
import work2 from "../../Assets/Images/work2.png";
import learn1 from "../../Assets/Images/learn1.png";
import learn2 from "../../Assets/Images/learn2.png";
import succeed1 from "../../Assets/Images/suceed1.png";
import succeed2 from "../../Assets/Images/suceed2.png";
import learnIcon from "../../Assets/Images/learnIcon.png";
import succeedIcon from "../../Assets/Images/succeedIcon.png";
import workIcon from "../../Assets/Images/workIcon.png";
import { SELAR_LINK } from "../../Utilities/constants";

const mission = [
  {
    title: "Learn",
    images: [learn1, learn2],
    icon: learnIcon,
  },
  {
    title: "Work",
    images: [work1, work2],
    icon: workIcon,
  },
  {
    title: "Succeed",
    images: [succeed1, succeed2],
    icon: succeedIcon,
  },
];

const missionRepeated = [...mission, ...mission];

const HomePageAffordable = () => {
  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <p>WE ARE AFFORDABLE</p>
        <h2>
          Skill up on any course with an initial sum of{" "}
          <span>
            ₦40,000
            <svg
              width="200"
              height="48"
              viewBox="0 0 200 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M198.435 14.9911C198.539 16.1749 198.107 17.4677 197.005 18.8806C195.899 20.299 194.165 21.7739 191.812 23.2713C187.107 26.2648 180.114 29.2318 171.31 31.9911C153.716 37.5049 129.101 42.1253 101.641 44.5276C74.1822 46.93 49.1384 46.6543 30.8549 44.2793C21.7048 43.0908 14.3029 41.3833 9.1499 39.2522C6.57217 38.1861 4.6086 37.0348 3.27324 35.83C1.94303 34.6298 1.293 33.4317 1.18943 32.2479C1.08586 31.0641 1.51796 29.7712 2.61955 28.3583C3.72542 26.94 5.45922 25.4651 7.81268 23.9676C12.5173 20.9741 19.5103 18.0072 28.3149 15.2478C45.9083 9.73407 70.5238 5.1137 97.983 2.71132C125.442 0.30895 150.486 0.5847 168.77 2.95962C177.92 4.14815 185.322 5.85571 190.475 7.98679C193.052 9.05283 195.016 10.2042 196.351 11.409C197.681 12.6091 198.332 13.8073 198.435 14.9911Z"
                stroke="#664EFE"
                stroke-width="2"
              />
            </svg>
          </span>
        </h2>
        <p>
          If you've made it this far, you must be at least a little curious.
          Sign up and take the first step toward your goals.
        </p>
        <Button
          onClick={() => {
            window.open(SELAR_LINK);
          }}
        >
          Start learning
        </Button>
      </div>

      <div className={classes.missionContainer}>
        {missionRepeated.map((data) => {
          return (
            <div key={data.title} className={`${classes.mission}`}>
              <div>
                <img src={data.icon} alt={data.title} />
                <span>{data?.title}</span>
              </div>
              <div className={classes.imageSection}>
                {data.images?.map((image, j) => {
                  return <img src={image} alt={data?.title} key={j} />;
                })}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default HomePageAffordable;
